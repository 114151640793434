var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      directives: [
        {
          name: "b-modal",
          rawName: "v-b-modal.modal-prevent-closing",
          modifiers: { "modal-prevent-closing": true },
        },
      ],
      ref: "standalone-hosting-modal",
      attrs: {
        id: "standalone-hosting-modal",
        "no-close-on-backdrop": "",
        "modal-class": "standalone-hosting-modal",
        centered: "",
        size: "lg",
        "hide-footer": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("div", { staticClass: "ml-2 mr-auto my-auto" }, [
                _vm._v("Use Your Domain"),
              ]),
              _c(
                "div",
                {
                  staticClass: "icon-container close-icon ml-auto mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [
                  _c("font-awesome-svg", {
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      type: "circle-xmark",
                      fill: "white",
                      width: 25,
                      height: 25,
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("p", { staticClass: "text-center m-0" }, [
        _vm._v(
          "\n    Tell us your domain name and we'll spin up your hosting to get you up and running today.\n  "
        ),
      ]),
      _c("br"),
      _c("p", { staticClass: "mb-0 ml-4" }, [
        _vm._v("\n    Have questions? Give us a call: "),
        _c("span", {
          directives: [
            {
              name: "phone-number",
              rawName: "v-phone-number",
              value: _vm.phone(),
              expression: "phone()",
            },
          ],
        }),
      ]),
      _c("hr"),
      _c("standalone-hosting-form", {
        attrs: { cancellable: true, bus: _vm.bus },
        on: {
          closeModal: _vm.closeModal,
          "next-slide": function ($event) {
            return _vm.$emit("next-slide")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }