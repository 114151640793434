<template>
  <b-modal
    id="standalone-hosting-modal"
    ref="standalone-hosting-modal"
    v-model="value"
    v-b-modal.modal-prevent-closing
    no-close-on-backdrop
    modal-class="standalone-hosting-modal"
    centered
    size="lg"
    hide-footer
  >
    <template #modal-header>
      <div class="ml-2 mr-auto my-auto">Use Your Domain</div>
      <div class="icon-container close-icon ml-auto mr-2" @click="closeModal()">
        <font-awesome-svg
          style="cursor: pointer"
          :type="'circle-xmark'"
          :fill="'white'"
          :width="25"
          :height="25"
        />
      </div>
    </template>
    <p class="text-center m-0">
      Tell us your domain name and we'll spin up your hosting to get you up and running today.
    </p>
    <br>
    <p class="mb-0 ml-4">
      Have questions? Give us a call: <span v-phone-number="phone()" />
    </p>
    <hr>
    <standalone-hosting-form
      :cancellable="true"
      :bus="bus"
      @closeModal="closeModal"
      @next-slide="$emit('next-slide')"
    />
  </b-modal>
</template>


<script>

import { phoneNumber } from '@/directives/directives'
export default {
  name: 'StandaloneHostingModal',
  components: {
    FontAwesomeSvg:      () => import('@/components/shared/FontAwesomeSvg.vue'),
    StandaloneHostingForm: () => import('@/components/Services/Hosting/StandaloneHostingForm.vue'),
  },
  directives: { phoneNumber },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      website: this.$store.getters['website/website'],
    }
  },
  methods: {
    closeModal() {
      this.$emit('input', false)
    },
    phone() {
      if (!this.website || !Array.isArray(this.website.phone_numbers)) {
        return null
      }

      return (this.website.phone_numbers.find((a) => a.kind === 'billing') || {}).phone_number
    },
  },
}
</script>


<style lang="scss" scoped>
.standalone-hosting-modal {
}
</style>
